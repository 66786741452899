<template>
  <div class="info-item__wrapper">
    <input type="checkbox" :checked="active" :id="`dropdown_${_uid}`" />
    <div class="info-item">
      <label :for="`dropdown_${_uid}`" class="info-item__head">
        <div class="info-item__head-title">
          <div class="title-container">
            <slot name="title"></slot>
          </div>
        </div>
        <div class="info-item__head-arrow ml-4">
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 1.25L8.75 8.75L1.25 1.25"
              :stroke="'#3C4242'"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </label>
      <div class="info-item__inner">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoDrop",
  props: {
    active: Boolean,
    color: String,
  },
  data: () => ({}),
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.info-item {
  padding-bottom: 0;
  transition: linear 0.1s;
  border-bottom: 1px solid rgba(66, 71, 83, 0.3);
  @media screen and (max-width: 767px) {
    transition: none;
  }
  &__inner {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: linear 0.1s;
  }
  &__head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 32px;
    padding-bottom: 24px;
    cursor: pointer;
    &-title {
      width: 95%;
      display: flex;
      align-items: center;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #3c4242;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
      span {
        margin-left: 8px;
        padding: 4px 8px;
        background: #3c4242;
        border-radius: 99px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #ffffff;
        @media screen and (max-width: 767px) {
          margin-left: 0;
          margin-bottom: 8px;
        }
      }
    }
    &-arrow {
      width: 18px;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: linear 0.3s;
      cursor: pointer;
    }
  }
  &__wrapper {
    & input {
      display: none;
      &:checked {
        & ~ .info-item {
          padding-bottom: 16px;
          .info-item {
            &__inner {
              max-height: 100%;
              opacity: 1;
              overflow: visible;
              @media screen and (max-width: 767px) {
                overflow: auto;
                max-height: calc(100% - 100px);
                padding-bottom: 32px;
              }
            }

            &__head-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>

